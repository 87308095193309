/*
* This is a manifest file that'll be compiled into application.css, which will include all the files
* listed below.
*
* Any CSS and SCSS file within this directory, lib/assets/stylesheets, vendor/assets/stylesheets,
* or vendor/assets/stylesheets of plugins, if any, can be referenced here using a relative path.
*
* You're free to add application-wide styles to this file and they'll appear at the bottom of the
* compiled file so the styles you add here take precedence over styles defined in any styles
* defined in the other CSS/SCSS files in this directory. It is generally better to create a new
* file per style scope.
*
*/

@import "navbar";
@import "yadcf_adjustments";
@import "runs";

body {
  padding-top: $navbar-height + 10px;
  padding-bottom: 30px;
}

.page-header {
  a.btn {
    float: right;
  }

  a.btn + a.btn {
    margin-right: 8px;
  }
}

.well {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3 {
    margin-top: 0;
    margin-bottom: 0;
  }
}

input[type="radio"],
input[type="checkbox"] {
  width: initial;
  height: initial;
  margin-top: 7px;
}

// make all images responsive by default
img {
  @extend .img-responsive;

  margin: 0 auto;
}

// apply styles to HTML elements
// to make views framework-neutral
main {
  @extend .container;

  padding-bottom: 80px;
  margin-top: 51px; // accommodate the navbar
}

section {
  @extend .row;

  margin-top: 20px;
}

// Styles for form views
// using Bootstrap 3
// generated by the rails_layout gem
.authform {
  padding-top: 30px;
  max-width: 400px;
  margin: 0 auto;
}

.authform form {
  @extend .well;
  @extend .well-lg;

  padding-bottom: 40px;
}

.authform .right {
  float: right !important;
}

.authform .button {
  @extend .btn;
  @extend .btn-primary;
}

.authform fieldset {
  @extend .well;
}

#error_explanation:not(:empty) {
  @extend .alert;
  @extend .alert-danger;
}

#error_explanation h2 {
  font-size: 16px;
}

.button-xs {
  @extend .btn;
  @extend .btn-primary;
  @extend .btn-xs;
}

.disabled {
  label {
    color: gray;
  }
}

.icon-size {
  font-size: 87px;
}

// Action Links
i.action.glyphicon {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 16px;
  line-height: 1.2;
  border-radius: 100%;
  background-color: #ddd;
}

a {
  > .glyphicon.action {
    background-color: $brand-primary;
    color: white;
  }

  &:hover .glyphicon.action {
    background-color: darken($brand-primary, 15%);
    color: white;
  }
}

.panel {
  .table {
    tr {
      td:first-child {
        padding-left: 16px;
      }

      th:first-child {
        padding-left: 16px;
      }
    }
  }
}

.panel-heading {
  a.toggle-collapse {
    color: $text-color;
  }
}

/* stylelint-disable font-family-no-missing-generic-family-keyword */
.panel-heading .toggle-collapse::after {
  /* symbol for "opening" panels */
  font-family: "Glyphicons Halflings"; /* essential for enabling glyphicon */
  content: "\e113"; /* adjust as needed, taken from bootstrap.css */
  float: right; /* adjust as needed */
  color: grey; /* adjust as needed */
}
/* stylelint-enable font-family-no-missing-generic-family-keyword */

.panel-heading.collapsed .toggle-collapse::after {
  /* symbol for "collapsed" panels */
  content: "\e114"; /* adjust as needed, taken from bootstrap.css */
}

body.show {
  .panel-heading {
    font-size: 16px;
    font-weight: bold;
  }
}

td.attribute-value {
  img {
    margin: 0;
  }
}
