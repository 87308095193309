table.dataTable {
  font-size: 13px;

  th {
    width: auto;
  }
}

.yadcf-filter.form-control {
  width: calc(100% - 15px);
}

.yadcf-filter.form-inline {
  width: calc(100% - 15px);
}

.yadcf-filter-wrapper {
  margin-top: 7px;
  margin-left: 0;
  display: block;
}

.yadcf-filter {
  font-weight: normal;
  margin-right: 10px;
}

.yadcf-filter-reset-button {
  position: absolute;
}

.yadcf-filter-range-date {
  width: 60px;
  height: 30px;
  padding: 2px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
  font-weight: normal;
}

.yadcf-filter-date {
  font-weight: normal;
  margin-right: 10px;
}

.yadcf-filter-wrapper-inner {
  display: inline-block;
  border: 0;
  margin-right: 10px;
}

.yadcf-filter-range-date-seperator {
  margin-left: 5px;
  margin-right: 5px;
}

table.dataTable thead > tr > th {
  padding-left: 0;
  padding-right: 0;
}

table.dataTable > thead > tr > th {
  padding-left: 8px;
  padding-right: 30px;
}

table.dataTable thead .sorting::after,
table.dataTable thead .sorting_asc::after,
table.dataTable thead .sorting_desc::after,
table.dataTable thead .sorting_asc_disabled::after,
table.dataTable thead .sorting_desc_disabled::after {
  bottom: inherit;
  top: 8px;
}
