#runs-index {
  th.distance {
    width: 90px;
  }

  th.steps {
    width: 90px;
  }

  th.people_count {
    width: 90px;
  }
}
