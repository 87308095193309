/* override for the 'Home' navigation link */
.navbar-brand {
  font-size: inherit;
  padding-top: 10px;

  img {
    height: 30px;
    width: auto;
  }
}

.navbar {
  -webkit-box-shadow: 0 8px 6px -6px #999;
  -moz-box-shadow: 0 8px 6px -6px #999;
  box-shadow: 0 8px 6px -6px #999;
}

.navbar-right {
  margin-right: 0;
}

.navbar-login {
  width: 305px;
  padding: 10px 10px 0;
}

.navbar-login-session {
  padding: 0 10px;
}

.navbar-login-menu {
  margin-right: 10px;
}

/* Make some room in overfilled navbar */
@media (min-width: 768px) and (max-width: 950px) {
  .nav {
    > li {
      > a {
        padding: 15px 8px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 950px) {
  .nav {
    font-size: 13px;
  }
}
